import * as React from "react";
import axios from "axios";
import { useUser } from "../../context/userContext";
import { Row, Col } from "react-bootstrap";
import { ContentCopy } from "@mui/icons-material";
import { Button, IconButton, Typography, Tooltip } from "@mui/material";
import { BootstrapInput } from "../../components/Inputs/BootstrapInput";
// modales
import SuccessModal from "../../components/Modals/SuccessModal/SuccessModal";
import WaitingModal from "../../components/Modals/WaitingModal/WaitingModal";
import ConfirmationModal from "../../components/Modals/ConfirmationModal/ConfirmationModal";
import PauseModal from "../../components/Modals/PauseModal/PauseModal";

const RelayerInfo = ({ _relayer, isContractPaused }) => {
  const { threshold, email, balance, address } = _relayer;

  const { getHeader } = useUser();

  const [newMail, setNewMail] = React.useState(email);
  const [newThreshold, setNewThreshold] = React.useState(threshold);

  const [openModalMail, setOpenModalMail] = React.useState(false);
  const [openModalUmbral, setOpenModalUmbral] = React.useState(false);

  const [emailChanged, setEmailChanged] = React.useState(false);
  const [thresholdChanged, setThresholdChanged] = React.useState(false);
  const [waitingModal, setWaitingModal] = React.useState(false);
  const [pauseModal, setPauseModal] = React.useState(false);
  const [contractChanged, setContractChanged] = React.useState(false);

  const change_mail = async () => {
    try {
      const headers = await getHeader();
      const body = {
        "email": newMail
      }
      setWaitingModal(true)
      const response = await axios.post(`${process.env.REACT_APP_API_BASE}/relayer/information/email`, body, { headers });
      console.log('Lambda que cambia el mail', response);
      setEmailChanged(true);
    } catch (error) {
      console.error(error)
      setWaitingModal(false)
    }
  };

  const change_umbral = async () => {
    try {
      const headers = await getHeader();
      const body = {
        "balance": (newThreshold * 1000000000000000000).toString()  // lo paso a wei
      };
      setWaitingModal(true)
      const response = await axios.post(`${process.env.REACT_APP_API_BASE}/relayer/information/threshold`, body, { headers });
      console.log('Lambda que cambia el umbral', response);
      setThresholdChanged(true);
    } catch (error) {
      console.error(error)
      setWaitingModal(false)
    }
  };

  const handlePauseContract = async () => {
    try {
      const headers = await getHeader();
      const body = {}
      setWaitingModal(true)
      const response = await axios.post(`${process.env.REACT_APP_API_BASE}/relayer/pause-contract`, body,{ headers });
     
      console.log('Lambda que pausa el contrato', response);
      setContractChanged(true);
    } catch (error) {
      console.error("Error pausando el contrato: ", error)
      setWaitingModal(false)
    }
  }

  return (
    <>
      {/* MODALES */}
      <SuccessModal _open={emailChanged} _handleClose={() => setEmailChanged(false)} _redirect={'/dashboard-relayer'} _subtitle={`Nuevo mail: ${newMail}`} _title={'Email a notificar actualizado'} />
      <SuccessModal _open={thresholdChanged} _handleClose={() => setThresholdChanged(false)} _redirect={'/dashboard-relayer'} _subtitle={`Nuevo umbral: ${newThreshold} MATIC`} _title={'Umbral de notificación actualizado'}  />
      <SuccessModal _open={contractChanged} _handleClose={() => setContractChanged(false)} _redirect={'/dashboard-relayer'} _subtitle={!isContractPaused ? `El contrato ha sido pausado correctamente` : `El contrato ha sido despausado correctamente`} _title={'Contrato actualizado'} />

      <WaitingModal _open={waitingModal} _handleClose={() => setWaitingModal(false)} _title={'Transacción ejecutandose'} />

      <ConfirmationModal _open={openModalMail} _handleClose={() => setOpenModalMail(false)} _title={'Cambiar mail a notificar'} _subtitle={'Esta acción no se puede deshacer. Por favor, confirme para continuar.'} _function={change_mail} />
      <ConfirmationModal _open={openModalUmbral} _handleClose={() => setOpenModalUmbral(false)} _title={'Cambiar umbral de notificación'} _subtitle={'Esta acción no se puede deshacer. Por favor, confirme para continuar.'} _function={change_umbral} />
      
      <PauseModal _open={pauseModal} _handleClose={() => setPauseModal(false)} _title={!isContractPaused ? 'Se pausará el contrato' : 'Se despausará el contrato'} _subtitle={!isContractPaused ? 'Una vez pausado, no se podrá mintear ningun certificado. Podrá volver a despausarlo desde esta página. Por favor, confirme para continuar.' : 'Podrá volver a pausarlo desde esta página.'} _function={handlePauseContract} />
      {/* PAGINA */}
      <Row className='mt-4'>
        <Col className="d-flex align-items-center" xl={3} xxl={2}>
          <Typography variant='h6' component='label' sx={{ fontWeight: '600', fontSize: '18px', lineHeight: '21.09px' }}>Balance:&nbsp;</Typography>
          <span>{balance}</span>
          &nbsp;&nbsp;
          <label>MATIC</label>
        </Col>
        <Col className="d-flex align-items-center">
          <Typography variant='h6' component='label' sx={{ fontWeight: '600', fontSize: '18px', lineHeight: '21.09px' }}>Address relayer: &nbsp;</Typography>
          <span>{address}</span>
          <Tooltip sx={{ marginLeft: '5px' }} title="Copiar address" arrow placement="right"><IconButton color="primary" onClick={() => navigator.clipboard.writeText(address)}><ContentCopy /></IconButton></Tooltip>
        </Col>
      </Row>
      <Row className='my-4'>
        <Col className="d-flex align-items-center" xl={5}>
          <Typography variant='h6' component='label' sx={{ fontWeight: '600', fontSize: '18px', lineHeight: '21.09px' }}>Umbral de notificación: &nbsp;</Typography>
          <BootstrapInput value={newThreshold} type="number"
            inputProps={{ min: 0 }}
            sx={{ width: '80px' }}
            onChange={(e) => setNewThreshold(e.target.value)} />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <label>MATIC</label>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button variant="contained" color="secondary" onClick={() => setOpenModalUmbral(true)} disabled={(newThreshold < 0) || (Number(newThreshold) === Number(threshold)) || !newThreshold}>
            GUARDAR
          </Button>
        </Col>
        <Col className="d-flex align-items-center">
          <Typography variant='h6' component='label' sx={{ fontWeight: '600', fontSize: '18px', lineHeight: '21.09px' }}>Mail a notificar: &nbsp;</Typography>
          <BootstrapInput value={newMail} type="email" onChange={(e) => setNewMail(e.target.value)} />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button variant="contained" color="secondary" onClick={() => setOpenModalMail(true)} disabled={(newMail === '') || (newMail === email) || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newMail))}>
            GUARDAR
          </Button>
        </Col>
      </Row>
      <Row className="my-5">
        <Col className="d-flex align-items-center">
          <Typography variant='h6' component='label' sx={{ fontWeight: '600', fontSize: '18px', lineHeight: '21.09px' }}>Estado minteo: &nbsp;&nbsp;&nbsp;&nbsp;</Typography>
          {isContractPaused
            ? <>
              <Typography variant='h6' component='span' sx={{ fontWeight: '700', fontSize: '25px', lineHeight: '29px', color: 'var(--main-color)' }}>Pausado</Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button variant="contained" color="secondary" sx={{ borderRadius: '40px', backgroundColor: '#33C481' }} onClick={() => setPauseModal(true)}>Activar Minteo</Button>
            </>
            : <>
              <Typography variant='h6' component='span' sx={{ fontWeight: '700', fontSize: '25px', lineHeight: '29px', color: 'var(--main-color)' }}>Activo</Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button variant="contained" color="success" sx={{ borderRadius: '40px', backgroundColor: '#ad2931' }} onClick={() => setPauseModal(true)}>{isContractPaused ? 'Despausar contrato' : 'Pausar contrato'}</Button>
            </>}
        </Col>
      </Row>
    </>
  );
};

export default RelayerInfo;