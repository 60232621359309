import * as React from "react";
import ModalCard from "../ModalCard";
import { Typography, Button } from "@mui/material";

const PauseModal = ({
  _open,
  _handleClose,
  _title = "Se pausará el contrato",
  _subtitle = "Una vez pausado, no se podrá mintear ningun certificado. Podrá volver a despausarlo desde esta página. Por favor, confirme para continuar.",
  _function,
}) => {

  const submitFunction = () => {
    _handleClose();
    _function();
  }

  return (
    <ModalCard
      className="confirmation-modal-properties modal-properties pause-modal"
      open={_open}
      handleClose={_handleClose}
      
    >
      <div className="modal-container" sx={{padding: '1rem !important'}}>
        <div className="d-flex align-items-center">
          <Typography variant="h6" sx={{ fontWeight: "500", fontSize: '1.5rem' }}>
            {_title}
          </Typography>
        </div>
        <Typography className={"modal-text mt-1"}>{_subtitle}</Typography>
      </div>

      <div className={"check-button-section mb-3"}>
        <Button variant="outlined" color="primary" onClick={_handleClose}>
          Volver
        </Button>
        <Button variant="contained" color="primary" onClick={submitFunction}>
          Confirmar
        </Button>
      </div>
    </ModalCard>
  );
};

export default PauseModal;
