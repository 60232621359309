export const cognitoConfig = {
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT,
    oauth: {
      domain: process.env.REACT_APP_DOMAIN,   // azure-userpool-dev.auth.us-west-1.amazoncognito.com
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: 'https://certificadosgalicia.com',
      redirectSignOut: 'https://certificadosgalicia.com',
      responseType: 'code'
    }
  }
};