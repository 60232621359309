/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import axios from 'axios';
import { Typography } from '@mui/material';
import { Container } from 'react-bootstrap';
import { useUser } from "../context/userContext";
import LogsDropdown from '../components/LogsDropdown/LogsDropdown';
import BounceLoader from "react-spinners/BounceLoader";

const Logs = () => {
    const { getHeader } = useUser();
    const [allFunctions, setAllFunctions] = React.useState([])
    const [initialLoading, setInitialLoading] = React.useState(true)
 
    const getStreamsName = async () => {
        try {
            const headers = await getHeader();
            const response = await axios.get(`${process.env.REACT_APP_API_BASE}/logs`, { headers });
            if (response.data.statusCode === 500 || response.data.statusCode === 404) return
            setAllFunctions(response.data.body);
        } catch (error) {
            console.error('error', error)
        } finally {
            setInitialLoading(false)
        }
    };
    React.useEffect(() => {
        getStreamsName()
    }, [])

    return (
        <Container>
            <Typography variant='h6' component='h2' color='primary' sx={{ fontSize: '1.75rem', fontWeight: 'bold', marginBottom: '1rem' }}>
                Logs
            </Typography>
            {initialLoading
            ?<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <BounceLoader color='#ff6600'/>
          </div>
            : allFunctions.length > 0
                ? <LogsDropdown _allStreams={allFunctions} /> 
                : <div>no hay logs</div>}
        </Container>
    )
}

export default Logs