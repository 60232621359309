/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { useNavigate } from "react-router-dom";

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {

  const [user, setUser] = React.useState(null);
  const [linksList, setLinksList] = React.useState([]);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      setUser(null);
      navigate("/login");
    } catch (error) {
      console.log('Sign out error', error);
    }
  };

  const handleLogin = () => {
    Auth.federatedSignIn({ provider: 'Galicia-Azure-AD-PROD' })
      .then(userData => setUser(userData))
      .catch(error => console.log('Federated sign in error', error));
  };

  const getUser = async () => {
    try {
      const [userData/*, userInfo*/] = await Promise.all([
        Auth.currentAuthenticatedUser(),
        Auth.currentUserInfo(),
      ]);
      // console.log(userData)
      // console.log(userInfo)
      return userData;
    } catch {
      console.log('Not signed in');
    }
  };

  const getHeader = async () => {
    try {
      const response = await Auth.currentAuthenticatedUser();
      return {
        Authorization: `Bearer ${response.signInUserSession.idToken.jwtToken}`
      }
    } catch (error) {
      console.log(error)
    }
  };

  const getLinkList = async () => {
    try {
      const header = await getHeader();
      const response = await axios.get(`${process.env.REACT_APP_API_BASE}/user/map-site?userRole=${'Admin'}`, { headers: header })

      setLinksList(response.data.body)
    } catch (error) {
      console.error('Error getting the link list: ', error);
    }
  };

  React.useEffect(() => {
    getUser()
      .then((response) => {

        if (user === null) setUser(response);

        getLinkList();
      })
  }, [user])

  return (
    <UserContext.Provider
      value={{
        user,
        getHeader,
        handleLogin,
        handleLogout,
        linksList,
        // renderSvg,
        // setRenderSvg,
        // certificate,
        // setCertificate
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => React.useContext(UserContext);
