import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/es'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const DatePickerComponent = ({ handleDateRangeChange }) => {
  const [selectedDateStart, setSelectedDateStart] = useState(null);
  const [selectedDateEnd, setSelectedDateEnd] = useState(null);

  const handleDateChangeStart = (date) => {
    setSelectedDateStart(date);
    handleDateRangeChange(date, selectedDateEnd);
  };

  const handleDateChangeEnd = (date) => {
    setSelectedDateEnd(date);
    handleDateRangeChange(selectedDateStart, date);
  };

  const renderInput = (props) => <TextField {...props} />;

  return (

    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Box display="flex" justifyContent="start" alignItems="center" >
        <Box mx={2} width="50%">
          <DatePicker
            label="Desde"

            value={selectedDateStart}
            onChange={handleDateChangeStart}
            renderInput={renderInput}
          />
        </Box>
        <Box width="50%" mx={2}>
          <DatePicker
            label="Hasta"
            value={selectedDateEnd}
            onChange={handleDateChangeEnd}
            renderInput={renderInput}
          />
        </Box>
      </Box>
    </LocalizationProvider>

  );
};

export default DatePickerComponent;
