import * as React from "react";
import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { ContentCopy } from "@mui/icons-material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Tooltip, Button, Box } from "@mui/material";
import DropdownSearch from "../DropdownSearch/DropdownSearch";
import { useNavigate } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DatePickerComponent from "../DatePicker/DatePickerComponent";

export default function AllCoursesTable({ header, rows, onVisibilityChange }) {
  const [filteredRows, setFilteredRows] = useState(rows);
  const [showVisibleRows, setShowVisibleRows] = useState(true);
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState("desc");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [nominatedFilter, setNominatedFilter] = useState('todosNominado')
  const [nominatedRows, setNominatedRows] = useState([])

  useEffect(() => {
    setFilteredRows(rows); // Actualiza filteredRows cuando cambian las rows
  }, [rows]);

  const [rowVisibility, setRowVisibility] = useState(
    rows.reduce((acc, row) => {
      acc[row.hashEvent] = row.visibility;
      return acc;
    }, {})
  );

  const formattedRows = rows.map((row) => ({
    ...row,
    creationDateString: new Date(row.creationDate),
  }));

  const getVisibleRows = () => {
    return showVisibleRows
      ? filteredRows.filter((row) => rowVisibility[row.hashEvent])
      : filteredRows;


  };

  const handleDateRangeChange = (start, end) => {
    const filteredRows = formattedRows.filter((row) => {
      const rowDate = new Date(row.creationDateString);
      return (!start || rowDate >= start) && (!end || rowDate <= end);
    });
    setFilteredRows(filteredRows);
    setStartDate(start);
    setEndDate(end);
  };

  const handleOptionClick = (option) => {
    const selectedRow = rows.find((row) => row.eventName === option);
    if (selectedRow) {
      navigate(`/cursos/${selectedRow.hashEvent}`);
    }
  };

  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleVisibilityChange = (hashEvent) => {
    // Mostrar confirmación para cambiar la visibilidad
    const confirmed = window.confirm(
      "¿Estás seguro de cambiar la visibilidad?"
    );

    // Si el usuario confirmó, actualiza la visibilidad y llama a la función onVisibilityChange
    if (confirmed) {
      setRowVisibility((prevVisibility) => ({
        ...prevVisibility,
        [hashEvent]: !prevVisibility[hashEvent],
      }));
      onVisibilityChange(
        hashEvent,
        rowVisibility[hashEvent],
        rows.find((row) => row.hashEvent === hashEvent).creationDate
      );
    }
  };

  const handleShowVisibleRows = () => {
    setShowVisibleRows(true);
  };

  const handleShowAllRows = () => {
    setShowVisibleRows(false);
  };

  const visibleRows = getVisibleRows();

  useEffect(() => {
    setNominatedRows(getFilteredNominatedRows());
  }, [nominatedFilter, showVisibleRows, filteredRows]);


  const getFilteredNominatedRows = () => {
    const visibleRows = getVisibleRows();

    if (nominatedFilter === 'nominado') {
      return visibleRows.filter((row) => row.isNominated);
    } else if (nominatedFilter === 'noNominado') {
      return visibleRows.filter((row) => !row.isNominated);
    } else {
      return visibleRows;
    }
  };

  /* const nominatedHandler = (value) => {
    console.log(value);

    // if (value === 'Todo') setNominatedRows([...visibleRows]);
    if (value === 'nominado') {
      setNominatedRows([...visibleRows.filter((row) => row.isNominated)]);
    }
    if (value === 'noNominado') {
      setNominatedRows([...visibleRows.filter((row) => !row.isNominated)]);
    }
    if (value === 'todosNominado') {
      setNominatedRows([...visibleRows]);

    }
  }; */

  console.log(nominatedRows);

  const handleNominados = (e) => {
    setNominatedFilter(e.target.value)
    /* nominatedHandler(e.target.value) */
    console.log('Filtro:', e.target.value)
  }


  const sortedRows = React.useMemo(() => {
    if (sortOrder === "asc") {
      return [...nominatedRows].sort(
        (a, b) =>
          new Date(a.creationDateString) - new Date(b.creationDateString)
      );
    } else {
      return [...nominatedRows].sort(
        (a, b) =>
          new Date(b.creationDateString) - new Date(a.creationDateString)
      );
    }
  }, [nominatedRows, sortOrder]);



  return (
    <>
      <Box display="flex" justifyContent="start" alignItems="center" my={3}>
        <button
          variant="text"
          className="mx-3 btn-underline"
          onClick={handleShowVisibleRows}
          style={{
            height: "40px",
            backgroundColor: "transparent",
            border: "none",
            borderBottomColor: showVisibleRows ? "red" : "gray",
            borderBottomStyle: "solid",
            borderBottomWidth: "4px",
            padding: 0,
            borderRadius: "5%",
            whiteSpace: 'nowrap'
          }}
        >
          Cursos Visibles
        </button>

        <button
          variant="text"
          className="mx-3 btn-underline"
          onClick={handleShowAllRows}
          style={{
            height: "40px",
            backgroundColor: "transparent",
            border: "none",
            borderBottomColor: !showVisibleRows ? "red" : "gray",
            borderBottomStyle: "solid",
            borderBottomWidth: "4px",
            padding: 0,
            borderRadius: "5%",
          }}
        >
          Todos
        </button>
        <Box ml={2} >
          <DropdownSearch
            list={rows.map((row) => row.eventName)}
            onFilter={(filteredList) =>
              setFilteredRows(
                rows.filter((row) => filteredList.includes(row.eventName))
              )
            }
            onOptionClick={handleOptionClick}
            
          />
        </Box>
        <Box mx={1} sx={{ width: "350px" }}>
          <DatePickerComponent handleDateRangeChange={handleDateRangeChange} />
        </Box>

        <Select
          value={nominatedFilter}
          onChange={handleNominados}
          sx={{
            width: "150px",
            borderRadius: "5px",
            borderWidth: "1px",
            marginRight: "10px",

            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              "& fieldset": {
                borderColor: "#8b0000",
              },
              "&:hover fieldset": {
                borderColor: "#8b0000",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#8b0000",
              },
              "& .MuiSelect-iconOutlined": {
                color: "#8b0000",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "red",
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root": {
                  color: "#8b0000",
                },
              },
            },
          }}
        >
          <MenuItem value="todosNominado">Todos</MenuItem>
          <MenuItem value="nominado">Nominado</MenuItem>
          <MenuItem value="noNominado">No Nominado</MenuItem>
        </Select>
        <Select
          value={"activo"}
          onChange={() => { }}
          sx={{
            cursor: 'crosshair',
            background: "lightGray",
            width: "150px",
            borderRadius: "5px",
            marginLeft: "10px",
            borderWidth: "1px",

            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              "& fieldset": {
                // borderColor: "#8b0000",
              },
              "&:hover fieldset": {
                // borderColor: "#8b0000",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#8b0000",
              },
              "& .MuiSelect-iconOutlined": {
                color: "#8b0000",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "red",
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root": {
                  color: "#8b0000",
                },
              },
            },
          }}
          disabled={true}
        >
          <MenuItem value="activo">Activo</MenuItem>
          <MenuItem value="pausa">En pausa</MenuItem>
          <MenuItem value="todos">Todos</MenuItem>
        </Select>
      </Box>

      <TableContainer component={Paper} sx={{ minWidth: 650 }}>
        <Table
          sx={{ minWidth: 650, fontWeight: "600" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {header.map((col, index) => (
                <TableCell
                  key={index}
                  align={"left"}
                  sx={{ fontWeight: "700" }}
                  onClick={col === "Fecha" ? handleSort : undefined}
                >
                  {col}
                  {/* {col === "Fecha" && (
                    <span style={{ marginLeft: "8px" }}>
                      {sortOrder === "asc" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                    </span>
                  )} */}
                </TableCell>
              ))}
              <TableCell align={"left"} sx={{ fontWeight: "700" }}>
                Visibilidad
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.map((row) => (
              <TableRow
                key={row.hashEvent}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  backgroundColor: !rowVisibility[row.hashEvent]
                    ? "#f5f5f5"
                    : "inherit", // Fondo gris claro para filas ocultas
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  sx={{
                    fontWeight: "500",
                    color: !rowVisibility[row.hashEvent] ? "gray" : "blue", // Texto gris para filas ocultas
                    maxWidth: "350px",
                  }}
                >
                  <Link
                    to={`/cursos/${row.hashEvent}`}
                    style={{
                      color: !rowVisibility[row.hashEvent] ? "gray" : "inherit",
                    }} // Enlace gris para filas ocultas
                  >
                    {row.eventName}
                  </Link>
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: !rowVisibility[row.hashEvent] ? "gray" : "inherit",
                  }}
                >
                  {new Date(row.creationDate).toLocaleString()}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: !rowVisibility[row.hashEvent] ? "gray" : "inherit",
                  }}
                >
                  {row.isNominated ? "Si" : "No"}
                </TableCell>
                <TableCell align="left">
                  <span
                    style={{
                      color: !rowVisibility[row.hashEvent] ? "gray" : "inherit",
                    }}
                  >
                    {row.hashEvent.slice(0, 15)}...{row.hashEvent.slice(-5)}
                  </span>
                  <Tooltip
                    sx={{ marginLeft: "5px" }}
                    title="Copiar hash"
                    arrow
                    placement="right"
                  >
                    <IconButton
                      color={
                        !rowVisibility[row.hashEvent] ? "default" : "primary"
                      } // Icono gris para filas ocultas
                      onClick={() =>
                        navigator.clipboard.writeText(row.hashEvent)
                      }
                    >
                      <ContentCopy />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <div
                    variant="contained"
                    color={
                      rowVisibility[row.hashEvent] ? "secondary" : "default"
                    }
                    onClick={() => handleVisibilityChange(row.hashEvent)} // Asociar handleVisibilityChange al evento onClick
                  >
                    {rowVisibility[row.hashEvent] ? (
                      <>
                        <VisibilityIcon style={{ color: "green" }} />
                      </>
                    ) : (
                      <VisibilityOffIcon style={{ color: "gray" }} />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}