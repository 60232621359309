/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import axios from 'axios';
import { Typography, Button } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';
import RelayerInfo from '../models/RelayerInfo/RelayerInfo';
import RelayerTransactions from '../models/RelayerTransactions/RelayerTransactions';
// useUser
import { useUser } from "../context/userContext";
// loaders
import Skeleton from '@mui/material/Skeleton';
import BounceLoader from "react-spinners/BounceLoader";

const tableLimit = 15;

const DashboardRelayer = () => {

    const [initialLoading, setInitialLoading] = React.useState(true);
    const [finalLoading, setFinalLoading] = React.useState(true);
    const { getHeader } = useUser();
    const [relayerInfo, setRelayerInfo] = React.useState();
    const [relayerTransactions, setRelayerTransactions] = React.useState([]);
    const [paginationData, setPaginationData] = React.useState({});
    const [isContractPaused, setIsContractPaused] = React.useState(false);

    React.useEffect(() => {
        setPausedState()
    }, [relayerInfo])

    //se fija si el contrato esta pausado
    const setPausedState = () => {
        if (!relayerInfo) return
        setIsContractPaused(relayerInfo.contractPaused);
    };

    const get_transactions = async (_limit, _lastKey) => {
        try {
            const headers = await getHeader();
            const transactions = await axios.get(`${process.env.REACT_APP_API_BASE}/relayer/transactions?count=${_limit}&lastKey=${_lastKey}`, { headers });
            if (transactions.data.statusCode === 404) return
            setPaginationData(transactions.data.pagination);
            setRelayerTransactions([...relayerTransactions, ...transactions.data.body]);
        } catch (error) {
            console.error(error);
        } finally {
            setInitialLoading(false);
        }
    };

    // paginacion
    const handlePaginationChange = () => {
        get_transactions(tableLimit, paginationData.nextPageKey)
    };

    const get_relayer_data = async () => {
        try {
            const headers = await getHeader();
            const info = await axios.get(`${process.env.REACT_APP_API_BASE}/relayer/information`, { headers })
            const json = JSON.parse(info.data.body)
            setRelayerInfo(json);
            setFinalLoading(false);
        } catch (error) {
            console.error(error)
        }
    };

    React.useEffect(() => {
        get_relayer_data();
        get_transactions(tableLimit, "");
    }, [])

    return (
        <Container>
            <Typography variant='h6' component='h2' color='primary' sx={{ fontSize: '1.75rem', fontWeight: 'bold', marginBottom: '1rem' }}>Dashboard Relayer</Typography>
            {finalLoading
                ? <>
                    <Row className="my-4">
                        <Col xs={3}><Skeleton variant="rectangular" width={200} height={40} /></Col>
                        <Col><Skeleton variant="rectangular" width={400} height={40} /></Col>
                    </Row>
                    <Row className="my-4">
                        <Col xs={3}><Skeleton variant="rectangular" width={300} height={40} /></Col>
                        <Col><Skeleton variant="rectangular" width={500} height={40} /></Col>
                    </Row>
                    <Row className="my-4">
                        <Col><Skeleton variant="rectangular" width={630} height={40} /></Col>
                    </Row>
                </>
                : <RelayerInfo isContractPaused={isContractPaused} _relayer={relayerInfo} />
            }
            {initialLoading
                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '30vh' }}>
                    <BounceLoader color='#ff6600' />
                </div>
                : relayerTransactions.length > 0
                    ? <>
                        <RelayerTransactions _transactions={relayerTransactions} />
                        {paginationData?.nextPageKey
                            ? <Row className='my-3'>
                                <Col className='mx-4 d-flex justify-content-end'>
                                    <Button variant='contained' color='primary' onClick={handlePaginationChange}>Cargar más transacciones</Button>
                                </Col>
                            </Row>
                            : null
                        }
                    </>
                    : <div>No hay transacciones aún</div>
            }
        </Container>
    )
}

export default DashboardRelayer